exports.components = {
  "component---src-mdx-about-mdx": () => import("./../../../src/mdx/about.mdx" /* webpackChunkName: "component---src-mdx-about-mdx" */),
  "component---src-mdx-archived-maths-fellegi-sunter-mdx": () => import("./../../../src/mdx/archived_maths_fellegi_sunter.mdx" /* webpackChunkName: "component---src-mdx-archived-maths-fellegi-sunter-mdx" */),
  "component---src-mdx-backing-vega-lite-mdx": () => import("./../../../src/mdx/backing_vega_lite.mdx" /* webpackChunkName: "component---src-mdx-backing-vega-lite-mdx" */),
  "component---src-mdx-bird-quiz-mdx": () => import("./../../../src/mdx/bird_quiz.mdx" /* webpackChunkName: "component---src-mdx-bird-quiz-mdx" */),
  "component---src-mdx-birdsong-mdx": () => import("./../../../src/mdx/birdsong.mdx" /* webpackChunkName: "component---src-mdx-birdsong-mdx" */),
  "component---src-mdx-carbon-electric-car-mdx": () => import("./../../../src/mdx/carbon_electric_car.mdx" /* webpackChunkName: "component---src-mdx-carbon-electric-car-mdx" */),
  "component---src-mdx-command-control-mdx": () => import("./../../../src/mdx/command_control.mdx" /* webpackChunkName: "component---src-mdx-command-control-mdx" */),
  "component---src-mdx-comparing-splink-models-mdx": () => import("./../../../src/mdx/comparing_splink_models.mdx" /* webpackChunkName: "component---src-mdx-comparing-splink-models-mdx" */),
  "component---src-mdx-comparing-splink-models-unsupervised-mdx": () => import("./../../../src/mdx/comparing_splink_models_unsupervised.mdx" /* webpackChunkName: "component---src-mdx-comparing-splink-models-unsupervised-mdx" */),
  "component---src-mdx-computing-fellegi-sunter-mdx": () => import("./../../../src/mdx/computing_fellegi_sunter.mdx" /* webpackChunkName: "component---src-mdx-computing-fellegi-sunter-mdx" */),
  "component---src-mdx-connected-components-mdx": () => import("./../../../src/mdx/connected_components.mdx" /* webpackChunkName: "component---src-mdx-connected-components-mdx" */),
  "component---src-mdx-country-energy-usage-mdx": () => import("./../../../src/mdx/country_energy_usage.mdx" /* webpackChunkName: "component---src-mdx-country-energy-usage-mdx" */),
  "component---src-mdx-demystifying-arrow-mdx": () => import("./../../../src/mdx/demystifying_arrow.mdx" /* webpackChunkName: "component---src-mdx-demystifying-arrow-mdx" */),
  "component---src-mdx-duckdb-quotes-links-mdx": () => import("./../../../src/mdx/duckdb_quotes_links.mdx" /* webpackChunkName: "component---src-mdx-duckdb-quotes-links-mdx" */),
  "component---src-mdx-effective-testing-mdx": () => import("./../../../src/mdx/effective_testing.mdx" /* webpackChunkName: "component---src-mdx-effective-testing-mdx" */),
  "component---src-mdx-em-algorithm-interactive-mdx": () => import("./../../../src/mdx/em_algorithm_interactive.mdx" /* webpackChunkName: "component---src-mdx-em-algorithm-interactive-mdx" */),
  "component---src-mdx-em-intuition-mdx": () => import("./../../../src/mdx/em_intuition.mdx" /* webpackChunkName: "component---src-mdx-em-intuition-mdx" */),
  "component---src-mdx-energy-usage-mdx": () => import("./../../../src/mdx/energy_usage.mdx" /* webpackChunkName: "component---src-mdx-energy-usage-mdx" */),
  "component---src-mdx-fast-deduplication-mdx": () => import("./../../../src/mdx/fast_deduplication.mdx" /* webpackChunkName: "component---src-mdx-fast-deduplication-mdx" */),
  "component---src-mdx-fellegi-sunter-accuracy-mdx": () => import("./../../../src/mdx/fellegi_sunter_accuracy.mdx" /* webpackChunkName: "component---src-mdx-fellegi-sunter-accuracy-mdx" */),
  "component---src-mdx-fill-country-solar-mdx": () => import("./../../../src/mdx/fill_country_solar.mdx" /* webpackChunkName: "component---src-mdx-fill-country-solar-mdx" */),
  "component---src-mdx-flight-distance-mdx": () => import("./../../../src/mdx/flight_distance.mdx" /* webpackChunkName: "component---src-mdx-flight-distance-mdx" */),
  "component---src-mdx-flights-mdx": () => import("./../../../src/mdx/flights.mdx" /* webpackChunkName: "component---src-mdx-flights-mdx" */),
  "component---src-mdx-index-mdx": () => import("./../../../src/mdx/index.mdx" /* webpackChunkName: "component---src-mdx-index-mdx" */),
  "component---src-mdx-interactive-blogging-mdx": () => import("./../../../src/mdx/interactive_blogging.mdx" /* webpackChunkName: "component---src-mdx-interactive-blogging-mdx" */),
  "component---src-mdx-intro-to-probabilistic-linkage-mdx": () => import("./../../../src/mdx/intro_to_probabilistic_linkage.mdx" /* webpackChunkName: "component---src-mdx-intro-to-probabilistic-linkage-mdx" */),
  "component---src-mdx-introducing-splink-mdx": () => import("./../../../src/mdx/introducing_splink.mdx" /* webpackChunkName: "component---src-mdx-introducing-splink-mdx" */),
  "component---src-mdx-just-mdx": () => import("./../../../src/mdx/just.mdx" /* webpackChunkName: "component---src-mdx-just-mdx" */),
  "component---src-mdx-left-join-mdx": () => import("./../../../src/mdx/left_join.mdx" /* webpackChunkName: "component---src-mdx-left-join-mdx" */),
  "component---src-mdx-live-splink-mdx": () => import("./../../../src/mdx/live_splink.mdx" /* webpackChunkName: "component---src-mdx-live-splink-mdx" */),
  "component---src-mdx-llm-short-term-thoughts-questions-mdx": () => import("./../../../src/mdx/llm_short_term_thoughts_questions.mdx" /* webpackChunkName: "component---src-mdx-llm-short-term-thoughts-questions-mdx" */),
  "component---src-mdx-llms-in-2025-mdx": () => import("./../../../src/mdx/llms_in_2025.mdx" /* webpackChunkName: "component---src-mdx-llms-in-2025-mdx" */),
  "component---src-mdx-m-and-u-values-mdx": () => import("./../../../src/mdx/m_and_u_values.mdx" /* webpackChunkName: "component---src-mdx-m-and-u-values-mdx" */),
  "component---src-mdx-m-u-generator-mdx": () => import("./../../../src/mdx/m_u_generator.mdx" /* webpackChunkName: "component---src-mdx-m-u-generator-mdx" */),
  "component---src-mdx-m-u-generator-starting-mdx": () => import("./../../../src/mdx/m_u_generator_starting.mdx" /* webpackChunkName: "component---src-mdx-m-u-generator-starting-mdx" */),
  "component---src-mdx-match-weight-calculator-mdx": () => import("./../../../src/mdx/match_weight_calculator.mdx" /* webpackChunkName: "component---src-mdx-match-weight-calculator-mdx" */),
  "component---src-mdx-match-weight-dependencies-mdx": () => import("./../../../src/mdx/match_weight_dependencies.mdx" /* webpackChunkName: "component---src-mdx-match-weight-dependencies-mdx" */),
  "component---src-mdx-maths-of-fellegi-sunter-mdx": () => import("./../../../src/mdx/maths_of_fellegi_sunter.mdx" /* webpackChunkName: "component---src-mdx-maths-of-fellegi-sunter-mdx" */),
  "component---src-mdx-microblog-mdx": () => import("./../../../src/mdx/microblog.mdx" /* webpackChunkName: "component---src-mdx-microblog-mdx" */),
  "component---src-mdx-offsetting-renewables-mdx": () => import("./../../../src/mdx/offsetting_renewables.mdx" /* webpackChunkName: "component---src-mdx-offsetting-renewables-mdx" */),
  "component---src-mdx-open-source-dividend-mdx": () => import("./../../../src/mdx/open_source_dividend.mdx" /* webpackChunkName: "component---src-mdx-open-source-dividend-mdx" */),
  "component---src-mdx-open-sourcing-analytical-work-mdx": () => import("./../../../src/mdx/open_sourcing_analytical_work.mdx" /* webpackChunkName: "component---src-mdx-open-sourcing-analytical-work-mdx" */),
  "component---src-mdx-parquet-api-mdx": () => import("./../../../src/mdx/parquet_api.mdx" /* webpackChunkName: "component---src-mdx-parquet-api-mdx" */),
  "component---src-mdx-partial-match-weights-mdx": () => import("./../../../src/mdx/partial_match_weights.mdx" /* webpackChunkName: "component---src-mdx-partial-match-weights-mdx" */),
  "component---src-mdx-posterior-treemap-mdx": () => import("./../../../src/mdx/posterior_treemap.mdx" /* webpackChunkName: "component---src-mdx-posterior-treemap-mdx" */),
  "component---src-mdx-prob-bf-mw-mdx": () => import("./../../../src/mdx/prob_bf_mw.mdx" /* webpackChunkName: "component---src-mdx-prob-bf-mw-mdx" */),
  "component---src-mdx-probabilistic-linkage-mdx": () => import("./../../../src/mdx/probabilistic_linkage.mdx" /* webpackChunkName: "component---src-mdx-probabilistic-linkage-mdx" */),
  "component---src-mdx-questions-senior-leaders-mdx": () => import("./../../../src/mdx/questions_senior_leaders.mdx" /* webpackChunkName: "component---src-mdx-questions-senior-leaders-mdx" */),
  "component---src-mdx-recommend-sql-mdx": () => import("./../../../src/mdx/recommend_sql.mdx" /* webpackChunkName: "component---src-mdx-recommend-sql-mdx" */),
  "component---src-mdx-spark-explain-mdx": () => import("./../../../src/mdx/spark_explain.mdx" /* webpackChunkName: "component---src-mdx-spark-explain-mdx" */),
  "component---src-mdx-spark-sort-mdx": () => import("./../../../src/mdx/spark_sort.mdx" /* webpackChunkName: "component---src-mdx-spark-sort-mdx" */),
  "component---src-mdx-splink-3-mdx": () => import("./../../../src/mdx/splink_3.mdx" /* webpackChunkName: "component---src-mdx-splink-3-mdx" */),
  "component---src-mdx-thanks-mdx": () => import("./../../../src/mdx/thanks.mdx" /* webpackChunkName: "component---src-mdx-thanks-mdx" */),
  "component---src-mdx-thorniest-problem-of-analytical-platforms-mdx": () => import("./../../../src/mdx/thorniest_problem_of_analytical_platforms.mdx" /* webpackChunkName: "component---src-mdx-thorniest-problem-of-analytical-platforms-mdx" */),
  "component---src-mdx-thought-provoking-llm-quotes-mdx": () => import("./../../../src/mdx/thought_provoking_llm_quotes.mdx" /* webpackChunkName: "component---src-mdx-thought-provoking-llm-quotes-mdx" */),
  "component---src-mdx-transforming-analytical-functions-mdx": () => import("./../../../src/mdx/transforming_analytical_functions.mdx" /* webpackChunkName: "component---src-mdx-transforming-analytical-functions-mdx" */),
  "component---src-mdx-two-years-of-llms-mdx": () => import("./../../../src/mdx/two_years_of_llms.mdx" /* webpackChunkName: "component---src-mdx-two-years-of-llms-mdx" */),
  "component---src-mdx-understanding-match-weights-mdx": () => import("./../../../src/mdx/understanding_match_weights.mdx" /* webpackChunkName: "component---src-mdx-understanding-match-weights-mdx" */),
  "component---src-mdx-visualising-fellegi-sunter-mdx": () => import("./../../../src/mdx/visualising_fellegi_sunter.mdx" /* webpackChunkName: "component---src-mdx-visualising-fellegi-sunter-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

